export interface ConfigItem {
  companyName: string
  appHost: string
  apiHost: string

  coreURL?: string
}

export interface Config extends ConfigItem {
  coreURL: string
}

export type CompanyName =
  | 'step'
  | 'nn'
  | 'vls'
  | 'gm'
  | 'kfha'
  | 'pyrus'
  | 'nlmk'
  | 'oes'
  | 'most'
  | 'eks'
  | 'ap'
  | 'ig'
  | 'energomost'
  | 'sti'
  | 'zs'
  | 'cg'
  | 'rgs'
  | 'geo'
  | 'su555'
  | 'rusteko'
  | 'rzd'
  | 'dml'
  | 'gcsu-555'
  | 'krno'
  | 'rsk'
  | 'blacksea'
  | 'samolet'

export const companyNames: CompanyName[] = [
  'step',
  'nn',
  'vls',
  'gm',
  'kfha',
  'pyrus',
  'nlmk',
  'oes',
  'most',
  'eks',
  'ap',
  'ig',
  'energomost',
  'sti',
  'zs',
  'cg',
  'rgs',
  'geo',
  'su555',
  'rusteko',
  'rzd',
  'dml',
  'gcsu-555',
  'krno',
  'rsk',
  'blacksea',
  'samolet',
]

export const isCompany = (companyName: any): companyName is CompanyName => {
  return companyNames.includes(companyName)
}

export const subDomain = window.location.host.split('.')[0]
export const currentCompanyName = isCompany(subDomain) ? subDomain : null
export const companyParam = currentCompanyName ? `&company=${currentCompanyName}` : ''

const configData: ConfigItem[] = [
  // ---------------dev-----------------------------
  {
    companyName: 'Test Company',
    appHost: 'redesign.finance.pragma.info',
    apiHost: 'https://redesign.api.finance.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  {
    companyName: 'Компания',
    appHost: 'dev.api.finance.pragma.info',
    apiHost: 'https://dev.api.finance.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  // ---------------demo-----------------------------
  {
    companyName: 'PragmaCore Demo',
    appHost: 'finance.pragma.info',
    apiHost: 'https://api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  // ---------------prod------------------------------
  {
    companyName: 'ООО «ГенМастер»',
    appHost: 'gm.finance.pragma.info',
    apiHost: 'https://gm.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'АО «Оргэнергострой»',
    appHost: 'oes.finance.pragma.info',
    apiHost: 'https://oes.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'АО «ГК «ЕКС»',
    appHost: 'eks.finance.pragma.info',
    apiHost: 'https://eks.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «Автоматизация Производств»',
    appHost: 'ap.finance.pragma.info',
    apiHost: 'https://ap.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'Incepta Group',
    appHost: 'ig.finance.pragma.info',
    apiHost: 'https://ig.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «ЭСК «Энергомост»',
    appHost: 'energomost.finance.pragma.info',
    apiHost: 'https://energomost.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «СТИ»',
    appHost: 'sti.finance.pragma.info',
    apiHost: 'https://sti.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «АО Золото Селигдара»',
    appHost: 'zs.finance.pragma.info',
    apiHost: 'https://zs.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'Capital Group',
    appHost: 'cg.finance.pragma.info',
    apiHost: 'https://cg.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ФГКУ Росгранстрой',
    appHost: 'rgs.finance.pragma.info',
    apiHost: 'https://rgs.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ГЕО',
    appHost: 'geo.finance.pragma.info',
    apiHost: 'https://geo.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «СУ-555»',
    appHost: 'su555.finance.pragma.info',
    apiHost: 'https://su555.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО ГК «СУ-555»',
    appHost: 'gcsu-555.finance.pragma.info',
    apiHost: 'https://gcsu-555.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «РУСТЕКО»',
    appHost: 'rusteko.finance.pragma.info',
    apiHost: 'https://rusteko.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ОАО «РЖД»',
    appHost: 'rzd.finance.pragma.info',
    apiHost: 'https://rzd.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ООО «Демолишн»',
    appHost: 'dml.finance.pragma.info',
    apiHost: 'https://dml.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'АО «КРНО»',
    appHost: 'krno.finance.pragma.info',
    apiHost: 'https://krno.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'ФБУ «РосСтройКонтроль»',
    appHost: 'rsk.finance.pragma.info',
    apiHost: 'https://rsk.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'Черное море',
    appHost: 'blacksea.finance.pragma.info',
    apiHost: 'https://blacksea.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
  {
    companyName: 'Группа «Самолет»',
    appHost: 'samolet.finance.pragma.info',
    apiHost: 'https://samolet.api.finance.pragma.info',

    coreURL: 'https://core.pragma.info/',
  },
]

const currentConfig = configData.find((it) => {
  return document.location.hostname === it.appHost
})

// export const config: Config = {
//     coreURL: currentConfig?.coreURL ?? 'https://dev.core.pragma.info/',
//     appHost: currentConfig?.appHost ?? 'localhost',
//     apiHost: currentConfig?.apiHost ?? 'https://dev.api.finance.pragma.info',
//     companyName: currentConfig?.companyName ?? 'Компания',
// }

export const config: Config = {
  coreURL: currentConfig?.coreURL ?? 'https://dev.core.pragma.info/',
  appHost: currentConfig?.appHost ?? 'localhost',
  apiHost: currentConfig?.apiHost ?? 'https://redesign.api.finance.pragma.info',
  companyName: currentConfig?.companyName ?? 'Test Company',
}
