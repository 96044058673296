import { FilterAlt, Remove } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  FormControl,
  IconProps,
  InputLabel,
  OutlinedTextFieldProps,
  SxProps,
  TabsProps,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, ReactNode } from 'react'
import { ICON_STYLES } from '../../global/styles/buttonStyles'
import { periodSelect } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'

interface IIconBtnProps extends ButtonProps {
  children?: React.ReactElement<IconProps>
}

interface IInnerContainerProps extends BoxProps {
  align?: 'left' | 'center' | 'right'
}

interface IPeriodTabs extends TabsProps {
  onReportTypeChange: (value: string) => void
}

interface ICategoryTabs extends TabsProps {
  onCategoryChange: (value: number | string) => void
}

interface ISelectProps extends Partial<OutlinedTextFieldProps> {
  onSelectChange: (value: string) => void
}

interface IFilterTitleProps {
  title: string
}

interface IPeriodWrapperProps {
  title?: string
  icon?: React.ReactNode
  rangeDividerIcon?: React.ReactNode
  selectStyle?: SxProps
  textFieldProps?: Partial<OutlinedTextFieldProps>
  rangeFromParams: {
    value: string | number | null
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    options: React.ReactNode | React.ReactNode[]
    children?: ReactNode
    placeholder?: string
  }
  rangeToParams: {
    value: string | number | null
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    options: React.ReactNode | React.ReactNode[]
    children?: ReactNode
    placeholder?: string
  }
  disabled?: boolean
  isTitleDisabled?: boolean
}

export const ControlPanel = {
  Wrapper: function Wrapper(props: BoxProps) {
    const { children } = props

    return (
      <Box
        className="ControlPanel"
        paddingY={1}
        paddingX={2.5}
        display="flex"
        alignItems="center"
        sx={{ width: '100%', backgroundColor: `${theme.palette.grey[100]}` }}
        position="sticky"
        zIndex={2}
        {...props}
      >
        {children}
      </Box>
    )
  },
  InnerContainer: function InnerContainer(props: IInnerContainerProps) {
    const { children, align } = props

    let margin = {}

    switch (align) {
      case 'left':
        margin = { mr: 'auto' }
        break
      case 'right':
        margin = { ml: 'auto' }
        break
      case 'center':
        margin = { marginX: 'auto' }
        break
    }

    return (
      <Box
        className="InnerContainer"
        display="flex"
        alignItems="center"
        minHeight={'38px'}
        sx={{ minWidth: 'fit-content' }}
        {...margin}
        {...props}
      >
        {children}
      </Box>
    )
  },
  Btn: function Btn(props: ButtonProps) {
    const { children, variant } = props
    return (
      <Button
        size="small"
        style={{ whiteSpace: 'nowrap', padding: '7px 8px' }}
        variant={variant || 'outlined'}
        {...props}
      >
        {children}
      </Button>
    )
  },
  IconBtn: function IconBtn(props: IIconBtnProps) {
    const { children, variant } = props

    return (
      <Button
        size="small"
        variant={variant || 'outlined'}
        color="primary"
        {...props}
        sx={{ padding: '7px 8px', width: '36px', height: '36px', ...props.sx }}
      >
        {/* <SvgIcon fontSize="small" color="primary"> */}
        {children}
        {/*   </SvgIcon> */}
      </Button>
    )
  },
  // ReportTypeTabs: function ReportTypeTabs(props: IPeriodTabs) {

  //     const { value, children, onReportTypeChange } = props

  //     return (
  //         <Tabs
  //             // sx={{ ...outlinedTabs, mr: 2.5 }}
  //             value={value}
  //             customStyle={"buttonGroup"}
  //             onChange={(event, value) => onReportTypeChange(value)}
  //             {...props}
  //         >
  //             {children}
  //         </Tabs>
  //     )
  // },
  // CategoryTabs: function CategoryTabs(props: ICategoryTabs) {

  //     const { value, children, onCategoryChange } = props

  //     return (
  //         <Tabs
  //             value={value}
  //             onChange={(event, value) => onCategoryChange(value)}
  //             {...props}
  //         >
  //             {children}
  //         </Tabs>
  //     )
  // },
  FilterTitle: function FilterTitle(props: IFilterTitleProps) {
    const { title } = props

    return (
      <Box display="flex" alignItems="center" mr={1}>
        <FilterAlt fontSize="small" sx={{ mr: 1 }} />
        <Typography variant="h4" textTransform="uppercase" lineHeight="auto !important">
          {title}
        </Typography>
      </Box>
    )
  },
  CalendarRange: function CalendarRange(props: IPeriodWrapperProps) {
    const {
      title,
      icon,
      rangeDividerIcon,
      selectStyle,
      rangeFromParams,
      rangeToParams,
      disabled,
      isTitleDisabled,
      textFieldProps,
    } = props

    return (
      <Box display="flex" alignItems="center" className="className-ControlPanel">
        {!isTitleDisabled && <ControlPanel.FilterTitle title="ПЕРИОД:" />}
        <FormControl>
          {!rangeFromParams.value && rangeFromParams.placeholder && (
            <InputLabel style={{ top: '-11px' }}>{rangeFromParams.placeholder}</InputLabel>
          )}
          <TextField
            disabled={disabled}
            key="rangeFrom"
            select
            value={rangeFromParams.value}
            onChange={(e) => rangeFromParams.onChange(e)}
            sx={selectStyle || periodSelect}
            style={{ width: rangeFromParams.value && rangeFromParams.placeholder ? 'auto' : '82px' }}
            {...textFieldProps}
          >
            {rangeFromParams.options}
          </TextField>
        </FormControl>
        {rangeFromParams.children}
        {rangeDividerIcon || <Remove sx={{ ...ICON_STYLES.size.small, marginX: 1 }} />}
        <FormControl>
          {!rangeToParams.value && rangeToParams.placeholder && (
            <InputLabel style={{ top: '-11px' }}>{rangeToParams.placeholder}</InputLabel>
          )}
          <TextField
            disabled={disabled}
            key="rangeTo"
            select
            value={rangeToParams.value}
            onChange={(e) => rangeToParams.onChange(e)}
            sx={selectStyle || periodSelect}
            style={{
              width: rangeToParams.value && rangeToParams.placeholder ? 'auto' : '82px',
            }}
            {...textFieldProps}
          >
            {rangeToParams.options}
          </TextField>
        </FormControl>
      </Box>
    )
  },
  Select: function Select(props: ISelectProps) {
    const { onSelectChange, value, children } = props

    return (
      <TextField
        select
        value={value}
        onChange={(e) => onSelectChange(e.target.value)}
        {...props}
        sx={{ ...periodSelect, ml: 1, ...props.sx }}
        size="small"
      >
        {children}
      </TextField>
    )
  },
  // PercentageDisplay: function PercentageDisplay() {
  //     const bdrParameters = useTypedSelector(bdrParametersSelector)
  //     const { isPercentageShown } = bdrParameters || {}
  //     const dispatch = useAppDispatch()

  //     function changePercentageDisplay(e: React.ChangeEvent<HTMLInputElement> | null, checked: boolean) {
  //         dispatch(setBdrParameters({ ...bdrParameters, isPercentageShown: checked }))
  //     }

  //     return (
  //         <Stack direction={'row'} alignItems={'center'} spacing={1}>
  //             <Checkbox checked={isPercentageShown} onChange={changePercentageDisplay} />
  //             <Typography variant="body2">Показать прирост в процентах</Typography>
  //         </Stack>
  //     )
  // },
}
