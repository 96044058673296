import { IReportRelationshipItem, TEstimateContractType, TOperationCategory } from '../types/commos-def'
import { getQueryParam } from '../functions'
import { History } from 'history'

export function getAvailableRelationships(
  category: TOperationCategory | TEstimateContractType,
  relationships: IReportRelationshipItem[],
  history: History,
) {
  const displayOrder: { [key: string]: number } = {
    INCOME: 1,
    OTHER_INCOMES: 2,
    OUTCOME: 3,
    OTHER_OUTCOMES: 4,
  }

  let targetArray =
    relationships
      ?.map((relationship) => {
        return {
          label: relationship.subBill.name + '',
          ...relationship,
        }
      })
      .filter((relationship) => {
        if (getQueryParam(history, 'budgetType') == 'bdr') {
          if (category == 'INCOME') {
            return relationship.type == 'INCOME' || relationship.type == 'OTHER_INCOMES'
          } else {
            return relationship.type != 'INCOME' && relationship.type != 'OTHER_INCOMES'
          }
        } else if (category == 'genContract') {
          return relationship.articles[0]?.type == 'INCOME' || relationship.articles[0]?.type == 'OTHER_INCOMES'
        } else if (category == 'subContract') {
          return relationship.articles[0]?.type != 'INCOME' && relationship.articles[0]?.type != 'OTHER_INCOMES'
        } else {
          if (relationship.articles[1]?.flow == 'OPERATIONS') {
            return
          }
          if (category == 'INCOME') {
            return relationship.articles[1]?.type == 'INCOME' || relationship.articles[1]?.type == 'OTHER_INCOMES'
          } else {
            return relationship.articles[1]?.type != 'INCOME' && relationship.articles[1]?.type != 'OTHER_INCOMES'
          }
        }
      }) || []

  if (getQueryParam(history, 'budgetType') == 'bdds') {
    return targetArray.sort((a, b) => {
      if (a.articles[0].name > b.articles[0].name) {
        return 1
      } else if (a.articles[0].name == b.articles[0].name) {
        return 0
      } else {
        return -1
      }
    })
  } else {
    return targetArray.sort((a, b) => {
      if (displayOrder[a.type] > displayOrder[b.type]) {
        return 1
      } else if (displayOrder[a.type] === displayOrder[b.type]) {
        return 0
      } else {
        return -1
      }
    })
  }
}
