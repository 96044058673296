import { Box, BoxProps, SxProps, TextField, TextFieldProps } from '@mui/material'
import { getFlexJustifyContent } from './utils'

export interface IEEStringProps {
  isDefaultValue?: boolean
  align?: 'center' | 'left' | 'right'
  name: string
  mode: 'edit' | 'view'
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  TextFieldProps?: TextFieldProps
  viewModeStyle?: SxProps
  BoxProps?: BoxProps
  maxLength?: number
}

export function EEString(props: IEEStringProps) {
  const { align, mode, value, onChange, TextFieldProps, viewModeStyle, BoxProps, maxLength } = props

  return mode === 'edit' ? (
    !props.isDefaultValue ? (
      <TextField
        value={value}
        onChange={onChange}
        {...TextFieldProps}
        inputProps={{
          maxLength: maxLength ? maxLength : 81,
        }}
        sx={{
          ...TextFieldProps?.sx,
          input: {
            textAlign: align || 'center',
            //@ts-ignore
            ...TextFieldProps?.sx['input'],
          },
        }}
      />
    ) : (
      <TextField
        defaultValue={value}
        onChange={onChange}
        {...TextFieldProps}
        inputProps={{
          maxLength: maxLength ? maxLength : 81,
        }}
        sx={{
          ...TextFieldProps?.sx,
          input: {
            textAlign: align || 'center',
            //@ts-ignore
            ...TextFieldProps?.sx['input'],
          },
        }}
      />
    )
  ) : (
    <Box
      {...BoxProps}
      sx={{
        ...viewModeStyle,
        justifyContent: align ? getFlexJustifyContent(align) : 'center',
        textAlign: align || 'center',
      }}
    >
      {value}
    </Box>
  )
}
