import qs from 'qs'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Add, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  FormControl,
  Pagination,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { SubBillAssignMode } from '../../components/SubBillAssignMode/SubBillAssignMode'
import { InterfaceContext } from '../../contexts/context.interface'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { ProductionProgramAPI } from '../../global/api/APIMethods/ProductionProgramAPI'
import { ReportAPI } from '../../global/api/APIMethods/ReportAPI'
import { IProjectRM } from '../../global/api/definitions'
import { changeQueryParams, getQueryParam } from '../../global/functions'
import { STYLED, table } from '../../global/styles/presets'
import {
  ExcelParseError,
  IFinanceCenter,
  IPrPrEstimateOperation,
  IPrPrPlanningAndAnaliticsOperation,
  IReportRelationshipItem,
  TEstimateContractType,
  TOperationCategory,
  UploadType,
} from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { AnaliticsTable } from './components/AnaliticsTable'
import { BudgetControls } from '../../components/BudgetControls/BudgetControls'
import { EstimateTable } from './components/EstimateTable'
import { PlanningTable } from './components/PlanningTable'
import { YearProlongationForm } from '../../components/YearProlongationForm/YearProlongationForm'
import { IFinanceCenterState } from '../BudgetPageNew/BudgetPageNew'
import { IncomeBeforeTaxesIcon } from '../../img/customIcons/IncomeBeforeTaxesIcon'
import { Sync } from '../../assets/SVG/Sync'

import DownloadingIcon from '@mui/icons-material/Downloading'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { FilterSelect } from './components/filter-select'
import { theme } from '../../global/styles/theme'
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from '../../global/variables'
import IntegrationDrawer from './components/IntegrationDrawer'
import { ReorderCheck } from '../../img/customIcons/ReorderCheck'
import UploadDrawer from './components/UploadDrawer'
import { socketChanel } from '../../global/api/socket'
import ErrorsListDrawer from '../../components/_NEW/ErrorsListDrawer'
import { useDidMountEffect } from '../../hooks/useDidMountEffect'
import UploadAlert from '../../components/_NEW/UploadAlert/UploadAlert'
import {
  createOperation,
  getEstimate,
  getPlanningAndAnalitics,
  isProlongationAvailable,
} from './ProductionProgramm-service'
import { IProductionProgramQueryParams, IProductionProgramState } from './ProductionProgram-def'
import { getAvailableRelationships } from '../../global/utils/getAvailableRelationships'

export function ProductionProgram() {
  const [state, setState] = React.useState({
    financeCenter: null,
    project: null,
    operations: null,
    total: null,
    recalculatedTotal: null,
    isSubBillAssignMode: false,
    activeSubbillToAssign: null,
    availableRelationships: null,
    areDetailsShown: true,
    refresh: false,
    totalRecords: 0,
    projectRMList: [] as IProjectRM[], // as IProjectRMList
  } as IProductionProgramState)

  const { projectID, financeCenterID } = useParams() as Params

  const history = useHistory()

  const location = useLocation()
  const interfaceCTX = useContext(InterfaceContext)

  const { t } = useTranslation('ENUMS')

  const { viewType, estimateType, contractType } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as IProductionProgramQueryParams

  /****************************************** Pagination ********************************************/
  const limitUrl = Number(getQueryParam(history, 'limit'))
  const offsetUrl = Number(getQueryParam(history, 'offset'))

  const [limitRow, setLimitRow] = useState<number>(Number(getQueryParam(history, 'limit')))
  const [pageNumber, setPageNumber] = useState<number>(1)
  const pageNumberStorage = localStorage.getItem('pageNumber')

  React.useEffect(() => {
    changeQueryParams(history, {
      offset: offsetUrl, // === 0 ? 0 : offsetMemo,
      limit: limitUrl === 0 ? 100 : limitRow,
    })
    setPageNumber(Number(pageNumberStorage || 1))
    return () => {
      localStorage.setItem('pageNumber', String(1))
    }
  }, [])

  React.useEffect(() => {
    changeQueryParams(history, {
      offset: offsetMemo,
    })
    localStorage.setItem('pageNumber', String(pageNumber))
  }, [pageNumber])

  React.useEffect(() => {
    setLimitRow(limitUrl)
  }, [limitUrl])

  const offsetMemo = useMemo(() => {
    return limitUrl * (pageNumber - 1)
  }, [limitUrl, pageNumber])

  const countPagination = useMemo(() => {
    return Math.ceil(state.totalRecords! / limitRow!) || 1
  }, [state.totalRecords, limitRow])

  const handleChangeLimit = useCallback(
    (limit: number) => {
      changeQueryParams(history, {
        limit: limit,
        offset: 0,
      })
      setPageNumber(1)
    },
    [setLimitRow],
  )

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      setPageNumber(pageNumber)
    },
    [setPageNumber],
  )

  /*******************************************************************************************/

  React.useEffect(() => {
    refreshRMProjectsList()
  }, [])

  React.useEffect(() => {
    state.financeCenter && initiateBreadcrumbs(state.financeCenter)
  }, [state.financeCenter])

  React.useEffect(() => {
    let queryParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as IProductionProgramQueryParams

    if (queryParams.viewType == 'ESTIMATE' && limitUrl > 0) {
      getEstimate(history, setState)
    }
    if (
      (queryParams.viewType == 'ANALITICS' || queryParams.viewType == 'PLANNING') &&
      location.search.includes('reportType')
    ) {
      getPlanningAndAnalitics(history, setState)
    }
    return () => {
      setState((prevState) => ({
        ...prevState,
        operations: null,
        total: null,
        recalculatedTotal: null,
      }))
    }
  }, [viewType, estimateType, state.isSubBillAssignMode, location.search, state.refresh])

  React.useEffect(() => {
    if (state.isSubBillAssignMode) {
      ReportAPI.getAllRelationships('bdr').then((data) =>
        setState((prevState) => ({
          ...prevState,
          availableRelationships: data.data,
        })),
      )
    } else {
      setState((prevState) => ({
        ...prevState,
        activeSubbillToAssign: null,
      }))
    }
  }, [state.isSubBillAssignMode])

  useDidMountEffect(() => {
    FinanceCenterAPI.getBudgetById(financeCenterID).then((data) => {
      setState((prevState) => ({
        ...prevState,
        financeCenter: data,
        project: data?.project || null,
      }))
    })
  }, [state.refresh])

  const refreshRMProjectsList = useCallback(() => {
    FinanceCenterAPI.getBudgetById(financeCenterID).then((data) => {
      setState((prevState) => ({
        ...prevState,
        financeCenter: data,
        project: data?.project || null,
      }))
    })
    ProductionProgramAPI.getRMProjectList(999, 0).then((data) => {
      setState((prevState) => ({
        ...prevState,
        projectRMList: data?.data,
      }))
    })
  }, [])

  function initiateBreadcrumbs(financeCenter: IFinanceCenter) {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'link',
        title: `Проект ${financeCenter.project!.name}`,
        path: `/projects/${financeCenter.project!.id}/menu`,
      },
      {
        variant: 'link',
        title: `Бюджеты`,
        path: `/projects/${financeCenter.project!.id}/budgets`,
      },
      {
        variant: 'title',
        title: `${t('FINANCE_CENTER_TYPES.' + financeCenter.type)} ${financeCenter.name}`,
      },
    ])
  }

  function switchSubBillAssignMode() {
    setState((prevState) => ({
      ...prevState,
      isSubBillAssignMode: !prevState.isSubBillAssignMode,
    }))
    changeQueryParams(history, {
      contractType: 'genContract',
    })
  }

  function switchShowHideDetails() {
    setState((prevState) => ({
      ...prevState,
      areDetailsShown: !prevState.areDetailsShown,
    }))
  }

  function openProlongationForm(isOpen: boolean) {
    setState((prevState) => ({
      ...prevState,
      isProlongationFormOpen: isOpen,
    }))
  }

  const handleRefresh = useCallback(() => {
    console.log('handleRefresh: ')
    setState((prevState) => ({
      ...prevState,
      refresh: !prevState.refresh,
    }))
    // setUploaded('start')
  }, [])

  /****************************************** Upload Drawer  *****************************************/
  const [closeUpload, setCloseUpload] = useState<boolean>(false)
  const [isUploaded, setUploaded] = useState<UploadType>('start')
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState<boolean>(false)
  const [errorList, setErrorList] = useState<ExcelParseError[]>([])

  const onUploadDrawerOpen = useCallback(() => {
    setIsUploadDrawerOpen(true)
    setIsIntegDrawerOpen(false)
  }, [])

  const onUploadDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      immediately || !dirty ? setIsUploadDrawerOpen(false) : openConfirm()
    },
    [isUploaded],
  )

  /****************************************** Drawer Integration ********************************************/
  const [isIntegDrawerOpen, setIsIntegDrawerOpen] = useState<boolean>(false)
  const [isIntegrateRM, setIsIntegrateRM] = useState<UploadType>('start')
  const [errorMsg, setErrorMsg] = React.useState<string>('')

  const onIntegDrawerOpen = useCallback(() => {
    setIsIntegDrawerOpen(true)
    setIsUploadDrawerOpen(false)
  }, [])

  const onIntegDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsIntegDrawerOpen(false) : openConfirm()
  }, [])

  /****************************************** Confirm Dialog *******************************************/

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      setCloseUpload(false)
      if (confirm) {
        if (isUploaded === 'loading' && closeUpload) {
          setIsUploadDrawerOpen(false)
        } else if (isUploaded !== 'loading') setIsUploadDrawerOpen(false)
        if (isUploaded === 'error') setUploaded('start')
        if (isUploaded === 'loading' && !closeUpload) onCancelUploadFile()
      }
      if (confirm) {
        if (isIntegrateRM === 'error') {
          setIsIntegDrawerOpen(false)
          setIsIntegrateRM('start')
        }
        if (isIntegrateRM === 'loading' && !closeUpload) setIsIntegDrawerOpen(false)
        if (isIntegrateRM === 'loading' && closeUpload) onCancelRmImport()
      }
    },
    [isUploaded, closeUpload, isIntegrateRM],
  )

  let body
  let title

  switch (true) {
    case !closeUpload && isIntegrateRM === 'loading':
    case closeUpload && isUploaded === 'loading':
      body = 'Форма будет закрыта, а загрузка данных продолжится!'
      break
    case isIntegrateRM === 'loading' && closeUpload:
    case isUploaded === 'loading' && !closeUpload:
      title = 'Вы уверены, что хотите отменить загрузку файла?'
      body = (
        <Typography variant="body2" component="p" align="center" sx={{ maxWidth: '600px' }}>
          Загрузка данных будет отменена. <br />
          Производственная программа останется без изменений.
        </Typography>
      )
      break
    default:
      body = undefined
      break
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    body,
    title,
  }

  const { ConfirmDialog, openConfirm, onClose: onCloseConfirm } = useConfirmDialog(dataForConfirmDialog)

  React.useEffect(() => {
    if (isUploaded === 'success' || isUploaded === 'error') onCloseConfirm()
    if (isIntegrateRM === 'success' || isIntegrateRM === 'error') onCloseConfirm()
  }, [isUploaded, isIntegrateRM])

  /****************************************** Errors List Drawer *************************************************/

  const [isErrorsListDrawerOpen, setIsErrorsListDrawerOpen] = useState<boolean>(false)

  const onErrorsListDrawerOpen = useCallback((errorList: ExcelParseError[]) => {
    setErrorList(errorList)
    setIsErrorsListDrawerOpen(true)
  }, [])

  const onErrorsListDrawerClose = useCallback(() => {
    setIsErrorsListDrawerOpen(false)
  }, [])

  /****************************************** websocket *************************************************/

  const auth = JSON.parse(localStorage.getItem('auth') || '{}')
  const [file, setFile] = useState<File>()

  const [progressTime, setProgressTime] = React.useState<string>('')
  const [progressPercent, setProgressPercent] = React.useState<number>(0)
  const [uploadRecords, setUploadRecords] = useState<number>(0)

  useEffect(() => {
    const authWS = {
      bodyJson: JSON.stringify({ token: auth.token }),
      id: financeCenterID,
      type: 'auth',
    }

    if (socketChanel.readyState === 1) {
      socketChanel.send(JSON.stringify(authWS))
    }

    socketChanel.onmessage = function (event) {
      let status = JSON.parse(event.data).type
      if (status === 'status_ok') {
        console.log('[socketChanel-open] Соединение открыто')
      }
      if (status === 'error') {
        let bodyJson = JSON.parse(event.data).bodyJson
        let msg = JSON.parse(bodyJson).msg
        console.log(`[socketChanel-error] Не авторизован, причина=${msg}.`)
      }
    }
    socketChanel.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}`)
      } else {
        console.log(`[socketChanel-close] Соединение прервано, причина=${event.reason}`)
      }
    }
  }, [socketChanel.readyState])

  socketChanel.onmessage = function (event) {
    let status = JSON.parse(event.data).type
    let syncId = JSON.parse(event.data).id
    if (socketChanel.readyState === 3) {
      const authWS = {
        bodyJson: JSON.stringify({ token: auth.token }),
        id: projectID,
        type: 'auth',
      }
      socketChanel.send(JSON.stringify(authWS))
    }

    if (status === 'upload_progress' && Number(financeCenterID) === syncId) {
      setUploaded('loading')
      let bodyJson = JSON.parse(event.data).bodyJson
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress
      setProgressPercent!(Number(progress))
      let totalRecords = Number(JSON.parse(bodyJson).total)
      setUploadRecords!(totalRecords)
    }

    if (status === 'sync_progress' && Number(financeCenterID) === syncId) {
      setIsIntegrateRM('loading')
      let bodyJson = JSON.parse(event.data).bodyJson
      let remainTime = JSON.parse(bodyJson).remainTime
      setProgressTime!(String(remainTime))
      let progress = JSON.parse(bodyJson).progress // %
      setProgressPercent!(Number(progress))
    }

    if (status === 'status_ok') {
      //setIsLoginSocket(true);
      console.log('[socketChanel-open-ProductionProgram] Соединение открыто')
    }
  }
  socketChanel.onclose = function (event) {
    if (event.wasClean) {
      console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}`)
    } else {
      console.log(`[socketChanel-close] Соединение прервано, причина=${event}`)
      handleRefresh()
    }
  }

  const onCancelUploadFile = useCallback(() => {
    const mesWS = {
      type: 'cancel_upload_excel_program',
      id: financeCenterID,
      bodyJson: JSON.stringify({
        projectID: projectID,
        financeCenterID: financeCenterID,
      }),
    }
    socketChanel!.send(JSON.stringify(mesWS))
  }, [socketChanel])

  const onCancelRmImport = useCallback(() => {
    if (socketChanel.readyState === 3) {
      handleRefresh()
    }
    const msgWS = {
      type: 'rm_sync_cancel',
      id: financeCenterID,
      bodyJson: JSON.stringify({
        projectID: projectID,
        financeCenterID: financeCenterID,
      }),
    }
    socketChanel!.send(JSON.stringify(msgWS))
  }, [socketChanel])

  //=======================================================================================================

  return (
    <Box>
      {!state.isSubBillAssignMode && (
        <>
          <ControlPanel.Wrapper>
            <ControlPanel.InnerContainer align="left">
              <Tabs
                value={viewType}
                onChange={(e, value) => {
                  setPageNumber(pageNumber)
                  changeQueryParams(history, {
                    viewType: value,
                    estimateType: value == 'ESTIMATE' ? 'WORKLOAD' : undefined,
                    reportType: undefined,
                    quarters: undefined,
                    monthStart: undefined,
                    monthEnd: undefined,
                    yearStart: undefined,
                    yearEnd: undefined,
                  })
                }}
                TabScrollButtonProps={{
                  disabled: true,
                }}
              >
                <Tab label="СМЕТА" value="ESTIMATE" disabled={!state.operations} />
                <Tab label="ПЛАНИРОВАНИЕ" value="PLANNING" disabled={!state.operations} />
                <Tab label="АНАЛИТИКА" value="ANALITICS" disabled={!state.operations} />
              </Tabs>
            </ControlPanel.InnerContainer>

            <ControlPanel.InnerContainer align="right" sx={{ gap: 1 }}>
              {/* inegrtaion rm */}
              <ControlPanel.IconBtn
                disabled={isUploaded === 'loading'}
                variant="outlined"
                style={{
                  width: '36px',
                  height: '36px',
                  justifyContent: 'space-between',
                  fill: isUploaded === 'loading' ? theme.palette.disabled?.main : theme.palette.primary.main,
                }}
                onClick={onIntegDrawerOpen}
              >
                <Sync />
              </ControlPanel.IconBtn>
              {viewType == 'ESTIMATE' && estimateType == 'WORKLOAD' && (
                <ControlPanel.IconBtn onClick={(e) => createOperation(e, history, state.operations![0]?.id, setState)}>
                  <Add fontSize="small" color="primary" />
                </ControlPanel.IconBtn>
              )}
              {viewType == 'ESTIMATE' && (
                <>
                  <ControlPanel.Btn
                    disabled={isIntegrateRM === 'loading' || isIntegrateRM === 'canceling'}
                    startIcon={<DownloadingIcon />}
                    onClick={onUploadDrawerOpen}
                  >
                    Загрузить файл
                  </ControlPanel.Btn>
                  <ControlPanel.Btn
                    sx={{
                      width: '165px',
                      padding: '7px 8px',
                      '& .MuiButton-startIcon': { marginLeft: '2px' },
                    }}
                    startIcon={<ReorderCheck />}
                    onClick={switchSubBillAssignMode}
                  >
                    Разнести статьи
                  </ControlPanel.Btn>
                </>
              )}
              {state.project && state.financeCenter && (
                <ControlPanel.Btn
                  startIcon={<IncomeBeforeTaxesIcon />}
                  disabled={isProlongationAvailable(state.project, state.financeCenter) == false}
                  onClick={(e) => openProlongationForm(true)}
                >
                  Продлить бюджет
                </ControlPanel.Btn>
              )}
            </ControlPanel.InnerContainer>
          </ControlPanel.Wrapper>
          {viewType == 'ESTIMATE' && (
            <ControlPanel.Wrapper>
              <ControlPanel.InnerContainer align="left" sx={{ justifyContent: 'space-between' }}>
                <Tabs
                  sx={STYLED.TABS.OUTLINED}
                  value={estimateType}
                  onChange={(e, value: string) =>
                    changeQueryParams(history, {
                      estimateType: value,
                    })
                  }
                >
                  <Tab disabled={!state.operations} label="ОБЪЕМ РАБОТ" value="WORKLOAD"></Tab>
                  <Tab disabled={!state.operations} label="ТРУДОЕМКОСТЬ" value="LABORIOUS"></Tab>
                </Tabs>
              </ControlPanel.InnerContainer>
              <Stack direction="row" spacing={2}>
                <Stack spacing={5} direction="row" justifyContent="flex-end" alignItems="center" sx={{ ml: 1 }}>
                  <FormControl sx={{ flexDirection: 'row' }} disabled={!state.operations}>
                    <FilterSelect
                      items={NUMBER_OF_ROWS_PER_USERS_PAGE}
                      value={limitRow}
                      onChange={handleChangeLimit}
                      startIcon={'Строк'}
                    />
                    <Pagination
                      disabled={!state.operations}
                      onChange={(e, v) => handleChangePage(v)}
                      count={countPagination}
                      shape="rounded"
                      page={pageNumber}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          '&.Mui-selected': {
                            background: 'rgba(109, 154, 220, 0.2)',
                            color: theme.palette.primary.main,
                            border: `1px solid ${theme.palette.primary.main}`,
                          },
                        },
                        '& .MuiPagination-ul': {
                          flexWrap: 'nowrap',
                        },
                      }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </ControlPanel.Wrapper>
          )}
          {(viewType == 'PLANNING' || viewType == 'ANALITICS') && state.financeCenter?.budgetYears && (
            <ControlPanel.Wrapper sx={{ justifyContent: 'space-between' }}>
              <ControlPanel.InnerContainer align="left" sx={{ mr: 2 }}>
                <BudgetControls
                  disabled={!state.operations}
                  variant={viewType == 'ANALITICS' ? 'report' : 'finCenter'}
                  availableYears={state.financeCenter?.budgetYears}
                />
              </ControlPanel.InnerContainer>
              {viewType == 'ANALITICS' && (
                <ControlPanel.InnerContainer align="right">
                  <Tooltip
                    placement="left"
                    title={state.areDetailsShown ? 'Показать детали' : 'Скрыть детали'}
                    PopperProps={{ disablePortal: true }}
                    disableInteractive
                    disableTouchListener
                    leaveDelay={0}
                  >
                    <ControlPanel.IconBtn
                      onClick={switchShowHideDetails}
                      startIcon={
                        state.areDetailsShown ? <Visibility fontSize="large" /> : <VisibilityOff fontSize="large" />
                      }
                      sx={{
                        svg: {
                          ml: 1.25,
                        },
                      }}
                    />
                  </Tooltip>
                </ControlPanel.InnerContainer>
              )}
              <Stack
                className="className-Pagination"
                spacing={5}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ ml: 1 }}
              >
                <FormControl sx={{ flexDirection: 'row' }} disabled={!state.operations}>
                  <FilterSelect
                    items={NUMBER_OF_ROWS_PER_USERS_PAGE}
                    value={limitRow}
                    onChange={handleChangeLimit}
                    startIcon={'Строк'}
                  />
                  <Pagination
                    disabled={!state.operations}
                    onChange={(e, v) => handleChangePage(v)}
                    count={countPagination}
                    shape="rounded"
                    page={pageNumber}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        '&.Mui-selected': {
                          background: 'rgba(109, 154, 220, 0.2)',
                          color: theme.palette.primary.main,
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                      },
                      '& .MuiPagination-ul': {
                        flexWrap: 'nowrap',
                      },
                    }}
                  />
                </FormControl>
              </Stack>
            </ControlPanel.Wrapper>
          )}
        </>
      )}
      {viewType == 'ESTIMATE' && estimateType == 'WORKLOAD' && !state.isSubBillAssignMode && (
        <EstimateTable.Wrapper>
          <EstimateTable.Workload.Headings />
          <TableBody>
            {state.operations &&
              state.operations.map((operation) => {
                return (
                  <EstimateTable.Workload.Row
                    key={operation.id}
                    prPrState={state}
                    setPrPrState={setState}
                    operation={operation as IPrPrEstimateOperation}
                  />
                )
              })}
            {!state.operations && <CircularProgress />}
          </TableBody>
          {state.recalculatedTotal && (
            <EstimateTable.Workload.Total total={state.recalculatedTotal as IPrPrEstimateOperation} />
          )}
        </EstimateTable.Wrapper>
      )}
      {viewType == 'ESTIMATE' && estimateType == 'LABORIOUS' && !state.isSubBillAssignMode && (
        <EstimateTable.Wrapper>
          <EstimateTable.Laborious.Headings />
          <TableBody>
            {state.operations &&
              state.operations.map((operation) => {
                return (
                  <EstimateTable.Laborious.Row
                    key={operation.id}
                    prPrState={state}
                    setPrPrState={setState}
                    operation={operation as IPrPrEstimateOperation}
                  />
                )
              })}
            {!state.operations && <CircularProgress />}
          </TableBody>
          {state.recalculatedTotal && (
            <EstimateTable.Laborious.Total total={state.recalculatedTotal as IPrPrEstimateOperation} />
          )}
        </EstimateTable.Wrapper>
      )}
      {viewType == 'PLANNING' && (
        <PlanningTable.Wrapper>
          <PlanningTable.Headings />
          <TableBody>
            {state.operations &&
              (state.operations as IPrPrPlanningAndAnaliticsOperation[])!.map((operation) => {
                return <PlanningTable.Row prPrState={state} setPrPrState={setState} operation={operation} />
              })}
            {!state.operations && <CircularProgress />}
          </TableBody>
          {state.total && (
            <TableFooter sx={table.footer.sticky}>
              <PlanningTable.TableTotalRow operation={state.recalculatedTotal as IPrPrPlanningAndAnaliticsOperation} />
            </TableFooter>
          )}
        </PlanningTable.Wrapper>
      )}
      {viewType == 'ANALITICS' && (
        <AnaliticsTable.Wrapper>
          <AnaliticsTable.Headings
            budgetYears={state.financeCenter ? state.financeCenter.budgetYears.map((year) => year.toString()) : []}
          />
          <TableBody>
            {state.operations &&
              (state.operations as IPrPrPlanningAndAnaliticsOperation[])!.map((operation) => {
                return <AnaliticsTable.Row withDetails={state.areDetailsShown} operation={operation} />
              })}
            {!state.operations && <CircularProgress />}
          </TableBody>
          {state.total && (
            <TableFooter sx={table.footer.sticky}>
              <AnaliticsTable.Row
                operationName="ВСЕГО"
                withDetails={state.areDetailsShown}
                operation={state.total as IPrPrPlanningAndAnaliticsOperation}
              />
            </TableFooter>
          )}
        </AnaliticsTable.Wrapper>
      )}
      {state.isSubBillAssignMode && state.operations ? (
        <SubBillAssignMode
          operationType="productionProgram"
          open={state.isSubBillAssignMode}
          onClose={switchSubBillAssignMode}
          operationsTableSxProps={{
            height: 'calc(100% - 140px)',
          }}
          budgetType={'bdr'}
          operations={state.operations}
          autocompleteOptions={getAvailableRelationships(
            getQueryParam(history, 'contractType') as TOperationCategory,
            state.availableRelationships as IReportRelationshipItem[],
            history,
          )}
          AutocompleteProps={{
            groupBy: (option) => t(`BDR_ARTICLE_CATEGORY.` + String(option.articles[0].type)),
          }}
          subBillAssignOperationRowCells={(operation) => {
            const { relationships } = operation as IPrPrEstimateOperation
            /* const contractType = getQueryParam(history, 'contractType') as
			  | 'genContract'
			  | 'subContract'; */
            const subBill = relationships![contractType]?.subBill.name || ''
            const gostBill = relationships![contractType]
              ? relationships![contractType]?.gostBill?.number + ' ' + relationships![contractType]?.gostBill?.name
              : ''
            return (
              <>
                <TableCell>{operation.name || ''}</TableCell>
                <TableCell>{subBill}</TableCell>
                <TableCell>{gostBill}</TableCell>
              </>
            )
          }}
        />
      ) : (
        state.isSubBillAssignMode && <CircularProgress />
      )}
      <YearProlongationForm
        open={state.isProlongationFormOpen}
        onClose={(e, r) => openProlongationForm(false)}
        variant="prProgram"
        finCenterState={{
          financeCenter: state.financeCenter,
          project: state.financeCenter?.project || null,
          refresh: state.refresh,
        }}
        setFinCenterState={setState as React.Dispatch<React.SetStateAction<Partial<IFinanceCenterState>>>}
        onCancel={() => openProlongationForm(false)}
      />
      {state.financeCenter && isUploadDrawerOpen && (
        <UploadDrawer
          open={isUploadDrawerOpen}
          onClose={onUploadDrawerClose}
          financeCenter={state.financeCenter}
          handleRefresh={handleRefresh}
          onErrorsListDrawerOpen={onErrorsListDrawerOpen}
          setCloseUpload={setCloseUpload}
          setFile={setFile}
          file={file!}
          setUploaded={setUploaded}
          isUploaded={isUploaded}
          setErrorList={setErrorList}
          errorList={errorList!}
          uploadRecords={uploadRecords}
          setUploadRecords={setUploadRecords}
          //setIsLoginSocket={setIsLoginSocket}
          // setProgressTime={setProgressTime}
          progressTimeProps={progressTime}
          progressPercentProps={progressPercent}
          //setProgressPercent={setProgressPercent}
        />
      )}
      {state.financeCenter && isIntegDrawerOpen && (
        <IntegrationDrawer
          open={isIntegDrawerOpen}
          onClose={onIntegDrawerClose}
          projectRMList={state.projectRMList}
          financeCenter={state.financeCenter!}
          handleRefresh={handleRefresh}
          handleRefreshRMList={refreshRMProjectsList}
          isIntegrateRM={isIntegrateRM}
          setIsIntegrateRM={setIsIntegrateRM}
          setCloseUpload={setCloseUpload}
          closeUpload={closeUpload}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        />
      )}
      {((isUploaded !== 'start' && !isUploadDrawerOpen) || (isIntegrateRM !== 'start' && !isIntegDrawerOpen)) && (
        <UploadAlert
          isDrawerOpen={isUploadDrawerOpen || isIntegDrawerOpen}
          setErrorMsg={setErrorMsg}
          isUploaded={isUploaded}
          isIntegrateRM={isIntegrateRM}
          onClickDrawerOpen={isUploaded === 'loading' ? onUploadDrawerOpen : onIntegDrawerOpen}
          setUploaded={setUploaded}
          setIsIntegrateRM={setIsIntegrateRM}
          progressTime={progressTime}
          progressPercent={progressPercent}
          handleRefresh={handleRefresh}
        />
      )}

      {errorList.length > 0 && (
        <ErrorsListDrawer open={isErrorsListDrawerOpen} onClose={onErrorsListDrawerClose} errorList={errorList} />
      )}
      <ConfirmDialog />
    </Box>
  )
}
