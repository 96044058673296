import React from 'react'
import {
  AlertProps,
  Autocomplete,
  AutocompleteProps,
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  ButtonProps,
  CircularProgress,
  ExtendButtonBase,
  FormControl,
  FormLabel,
  FormLabelProps,
  InputLabel,
  MenuItem,
  MenuItemProps,
  MenuItemTypeMap,
  StandardTextFieldProps,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ChangeEvent, useContext } from 'react'
import {
  Controller,
  FieldValues,
  FormProvider,
  FormProviderProps,
  RegisterOptions,
  useForm,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form'
import { autoComplete, datePicker, input, select } from '../../global/styles/presets'
import { isAfter, isBefore } from 'date-fns'
import { ProjectDetail } from '../ProjectCard/components/ProjectDetail'
import { AlertDialog, IAlertDialog } from '../AlertCustom/AlertDialog'
import { Delete } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import ru from 'date-fns/esm/locale/ru/index.js'
import { theme } from '../../global/styles/theme'
import { StyledTextFieldTitle } from './styles'
import { Resolver } from 'react-hook-form/dist/types/resolvers'
import { SubBillAssign } from '../SubBillAssign/SubBillAssign'

interface IFormProps {
  children: React.ReactNode | React.ReactNode[]
  getValues?: (values: any) => void
  defaultValues: {
    [key: string]: any
  }
  onSubmit: (data: any) => void
  methods: UseFormReturn<any, any>
  resolver?: Resolver
  sx?: SxProps
}

interface IControlableFormFieldProps extends StandardTextFieldProps {
  name: string
  TextFieldProps?: TextFieldProps
  stylePreset?: SxProps
  rules?: RegisterOptions
  selectPlaceholder?: string
}

interface IControllableNumberFormatProps extends IControlableFormFieldProps {
  TextFieldProps: {
    placeholder?: string
    label?: string
    error?: boolean
  }
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

// interface IControlableSelectFieldProps extends Omit<IControlableFormFieldProps, TextFieldProps> {

// }

interface IControlableDatePickerProps extends IControlableFormFieldProps {
  DatePickerProps?: Partial<DatePickerProps<any, any>>
}

interface IFieldLabel extends Partial<FormLabelProps> {
  title?: string
  icon?: React.ReactNode
  BoxProps?: BoxProps
  children: React.ReactNode | React.ReactNode[]
}

interface IFormBtnProps {
  onClick?: (methods: UseFormReturn<FieldValues, any>, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  BtnProps?: ButtonProps
  withAlert?: boolean
  body?: string
}

interface IControlableAutocompleteProps extends IControlableFormFieldProps {
  options: any[]
  BoxProps?: BoxProps
  AutocompleteProps?: Partial<AutocompleteProps<any, undefined, undefined, undefined>>
}

const defaultValidationSchema = {
  date: {
    isDateValid: (v: any) => v != 'Invalid Date' || 'Неверная дата',
    isYearValid: (v: any) => new Date(v)?.getFullYear().toString().length >= 4 || 'Неверная дата',
    isLessThanMinDate: (v: any) => isBefore(new Date(1999, 11, 31), v) || 'Мин. 2000г',
    isBiggerThanMaxDate: (v: any) => isAfter(new Date(2100, 11, 31), v) || 'Макс. 2100г',
  },
}

interface FormContextProps {
  isAlertOpen: boolean
  btnFnInheritedToAlert: (
    methods: UseFormReturn<FieldValues, any>,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void | undefined
  initiateFnFromBtn: (
    fn: (methods: UseFormReturn<FieldValues, any>, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) => void
  switchIsAlertOpen: () => void
}

export const FormContext = React.createContext({} as FormContextProps)
export function FormContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [value, setValue] = React.useState({
    isAlertOpen: false,
    btnFnInheritedToAlert: undefined,
  } as unknown as FormContextProps)

  function switchIsAlertOpen() {
    setValue((prevValue) => ({
      ...prevValue,
      isAlertOpen: !prevValue.isAlertOpen,
    }))
  }

  function initiateFnFromBtn(
    fn: (methods: UseFormReturn<FieldValues, any>, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) {
    setValue((prevValue) => ({
      ...prevValue,
      btnFnInheritedToAlert: fn,
    }))
  }

  return (
    <FormContext.Provider
      value={{
        ...value,
        switchIsAlertOpen: switchIsAlertOpen,
        initiateFnFromBtn: initiateFnFromBtn,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const Form = {
  Wrapper: function Wrapper(props: IFormProps) {
    const { children, defaultValues, onSubmit, getValues, resolver, sx, methods } = props

    // const _methods = useForm({
    //     mode: "all",
    //     defaultValues: { ...defaultValues },
    //     resolver,
    // })

    React.useEffect(() => {
      getValues && getValues(methods.getValues())
    }, [methods.formState.isValidating])

    return (
      <FormProvider {...methods}>
        <FormContextProvider>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Box p={2.5} display="flex" flexDirection="column" gap={1.5} height="100%" sx={sx}>
              {children}
            </Box>
          </form>
        </FormContextProvider>
      </FormProvider>
    )
  },
  FieldLabel: function Label(props: IFieldLabel) {
    const { title, icon, BoxProps, children } = props

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" {...BoxProps}>
        <ProjectDetail
          MUIBoxProps={{
            pt: 1,
          }}
          title={title || ''}
          icon={icon}
        />
        {children}
      </Box>
    )
  },
  FieldTitle: function Title(props: IFieldLabel) {
    const { title, children } = props

    return (
      <FormLabel sx={{ textAlign: 'left', ...props.sx, ...(props.title && { mb: 2 }) }}>
        <StyledTextFieldTitle mb={0.75} variant="tooltip" pl={2}>
          {title}
        </StyledTextFieldTitle>
        {children}
      </FormLabel>
    )
  },
  ControlableTextField: function ControlableTextField(props: IControlableFormFieldProps) {
    const methods = useFormContext()

    const { name, rules } = props

    return (
      <Controller
        name={name}
        control={methods.control}
        rules={{ ...rules }}
        render={({ field }) => {
          return (
            <Box>
              <TextField
                {...field}
                helperText={methods.formState.errors[name]?.message}
                error={!!methods.formState.errors[name] || !!props?.error}
                focused={!!methods.formState.errors[name]?.message || undefined}
                {...props}
              />
            </Box>
          )
        }}
      />
    )
  },
  ControlableNumberFormat: function ControlableNumberFormat(props: IControllableNumberFormatProps) {
    const methods = useFormContext()

    const { name, stylePreset, rules, onChange, TextFieldProps } = props

    return (
      <Controller
        name={name}
        control={methods.control}
        rules={rules}
        render={({ field }) => {
          return (
            <Box>
              <NumberFormat
                {...field}
                {...TextFieldProps}
                customInput={TextField}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  onChange && onChange(e)
                }}
                allowLeadingZeros={false}
                variant={'outlined'}
                helperText={methods.formState.errors[name]?.message}
                error={!!methods.formState.errors[name] || !!TextFieldProps?.error}
                focused={!!methods.formState.errors[name] || undefined}
                sx={stylePreset || input.drawerForm}
              />
            </Box>
          )
        }}
      />
    )
  },
  ControlableSelect: function ControlableSelect(props: IControlableFormFieldProps) {
    const methods = useFormContext()

    const { name, TextFieldProps, stylePreset, rules, selectPlaceholder, children } = props

    return (
      <Controller
        name={name}
        control={methods.control}
        rules={{ ...rules }}
        render={({ field }) => {
          return (
            <Box>
              <FormControl>
                {selectPlaceholder && (
                  <InputLabel
                    style={{
                      top: '-10px',
                      left: '32%',
                      // left: '65px',
                      fontSize: '14px',
                      color: theme.palette.text.disabled,
                    }}
                  >
                    {selectPlaceholder}
                  </InputLabel>
                )}
                <TextField
                  select
                  {...field}
                  {...props}
                  helperText={methods.formState.errors[name]?.message}
                  error={!!methods.formState.errors[name] || props.error}
                  focused={!!methods.formState.errors[name]?.message || undefined}
                >
                  {children}
                </TextField>
              </FormControl>
            </Box>
          )
        }}
      />
    )
  },
  ControllableDatePicker: function ControllableDatePicker(props: IControlableDatePickerProps) {
    const methods = useFormContext()

    const { name, rules, TextFieldProps, DatePickerProps, stylePreset } = props

    return (
      <Controller
        name={name}
        control={methods.control}
        rules={{
          ...rules,
          validate: {
            ...defaultValidationSchema.date,
            ...rules?.validate,
          },
        }}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
              <DatePicker
                {...field}
                inputFormat="dd.MM.yyyy"
                minDate={new Date(2000, 0, 1)}
                maxDate={new Date(2100, 11, 31)}
                {...DatePickerProps}
                renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextField
                    {...TextFieldProps}
                    {...props}
                    helperText={methods.formState.errors[name]?.message}
                    error={!!methods.formState.errors[name] || !!TextFieldProps?.error}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: 'дд.мм.гггг',
                      ...TextFieldProps?.inputProps,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )
        }}
      />
    )
  },
  ControlableAutocomplete: function ControlableAutocomplete(props: IControlableAutocompleteProps) {
    const methods = useFormContext()

    const { name, rules, TextFieldProps, options, stylePreset, BoxProps, AutocompleteProps } = props

    return (
      <Controller
        key={name}
        name={name}
        control={methods.control}
        rules={{ ...rules }}
        render={({ field, fieldState }) => (
          <Box width="100%" {...BoxProps}>
            <Autocomplete
              key={name}
              {...field}
              isOptionEqualToValue={(option, value) => option === value}
              disablePortal
              options={options}
              onChange={(event, value) => {
                // console.log(fieldState.error?.message)
                field.onChange(value)
              }}
              noOptionsText={'Нет субсчетов'}
              {...(props.sx as Omit<Partial<SxProps>, 'color'>)}
              sx={{
                // "::-webkit-input-placeholder": {/
                zIndex: 1,
                // }
              }}
              // sx={autoComplete.drawerForm}
              loading={options == null}
              loadingText={'Загрузка'}
              {...AutocompleteProps}
              renderInput={(props) => (
                <TextField
                  {...props}
                  helperText={methods.formState.errors[name]?.message}
                  error={!!methods.formState.errors[name] || !!TextFieldProps?.error}
                  focused={!!methods.formState.errors[name]?.message || undefined}
                  InputProps={{
                    ...props.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {AutocompleteProps?.loading ? (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            sx={{
                              top: 0,
                              left: 0,
                              position: 'relative',
                              color: theme.palette.primary.main,
                            }}
                          />
                        ) : null}
                        {props.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  {...TextFieldProps}
                />
              )}
            />
          </Box>
        )}
      />
    )
  },
  BtnGroup: function BtnGroup(props: BoxProps) {
    const { children } = props

    return (
      <Box display={'flex'} sx={{ gap: 1.5, boxShadow: 'none', width: '100%' }} {...props}>
        {children}
      </Box>
    )
  },
  SubmitButton: function SubmitButton(props: IFormBtnProps) {
    const formCTX = useContext(FormContext)

    const methods = useFormContext()

    const { onClick, BtnProps, body } = props

    return (
      <Button
        disabled={formCTX.isAlertOpen}
        variant="contained"
        color="success"
        type="submit"
        fullWidth
        onClick={(e) => onClick && onClick(methods, e)}
        {...BtnProps}
        sx={{ ...BtnProps?.sx }}
      >
        {body || 'Сохранить'}
      </Button>
    )
  },
  CancelBtn: function SubmitButton(props: IFormBtnProps) {
    const methods = useFormContext()

    const formCTX = useContext(FormContext)

    const { onClick, BtnProps } = props

    return (
      <Button
        disabled={formCTX.isAlertOpen}
        color="primary"
        variant="contained"
        onClick={(e) => onClick && onClick(methods, e)}
        {...BtnProps}
        sx={{ width: '100%', ...BtnProps?.sx }}
      >
        Отменить
      </Button>
    )
  },
  DeleteBtn: function DeleteBtn(props: IFormBtnProps) {
    const methods = useFormContext()

    const formCTX = useContext(FormContext)

    const { onClick, BtnProps, withAlert } = props

    React.useEffect(() => {
      formCTX.initiateFnFromBtn(() => {
        onClick && onClick(methods)
      })
    }, [withAlert])

    return (
      <Button
        disabled={formCTX.isAlertOpen}
        variant="text"
        color="error"
        startIcon={<Delete />}
        onClick={(e) => (withAlert ? formCTX.switchIsAlertOpen() : onClick && onClick(methods, e))}
        {...BtnProps}
        sx={{ color: theme.palette.error.main, width: '100%', ...BtnProps?.sx }}
      >
        Удалить
      </Button>
    )
  },
  Alert: function Alert(props: IAlertDialog) {
    const { title, severity, message, onYes, onNo } = props

    const methods = useFormContext()

    const formCTX = useContext(FormContext)

    return (
      <Box p={2} display="flex" marginY="auto">
        <AlertDialog
          title={title}
          severity={severity}
          open={formCTX.isAlertOpen}
          message={message}
          onYes={(e) => formCTX.btnFnInheritedToAlert(methods, e)}
          onNo={(e) => (onNo ? onNo(e) : formCTX.switchIsAlertOpen())}
        />
      </Box>
    )
  },
}
