import { ExcelUploadResponse, IPrPrEstimateOperation, IPrPrPlanningAndAnaliticsOperation } from '../../types/commos-def'
import { errorHandler, errorUploadHandler, parseObjectToQueryString, req, successHandler } from '../api'
import {
  IBudgetYearlyReportQueryParams,
  IProductionProgramEstimateGetListQuery,
  IProductionProgramEstimateResponse,
  IProjectRMList,
  IRmImportResponse,
  IUploadProgress,
} from '../definitions'
import { Buffer } from 'buffer'

export class ProductionProgramAPI {
  static baseURL: string = '/production-program'
  static async getEstimate(
    query: IProductionProgramEstimateGetListQuery,
    /*   limit: number,
      offset: number, */
  ): Promise<IProductionProgramEstimateResponse> {
    return await req
      .get(this.baseURL + '/estimate/get-list' + parseObjectToQueryString(query))
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
  static async createOperation(
    body: IPrPrEstimateOperation,
    query: IProductionProgramEstimateGetListQuery,
  ): Promise<number> {
    return await req
      .post(this.baseURL + `/estimate/create` + parseObjectToQueryString(query), body)
      .then(({ data }) => {
        successHandler('Операция создана')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updateOperationV2(body: IPrPrEstimateOperation): Promise<void> {
    return await req
      .put(this.baseURL + `/estimate/${body.id}/v2`, body)
      .then(({ data }) => {
        successHandler('Операция изменена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updateOperation(body: IPrPrEstimateOperation): Promise<void> {
    return await req
      .put(this.baseURL + `/estimate/${body.id}`, body)
      .then(({ data }) => {
        return successHandler('Операция изменена')
      })
      .catch((error) => errorHandler(error))
  }

  static async updatePlanningOperationV2(
    body: IPrPrPlanningAndAnaliticsOperation,
    query: Partial<IBudgetYearlyReportQueryParams> & { year: number | string },
  ): Promise<IPrPrPlanningAndAnaliticsOperation> {
    return await req
      .post(this.baseURL + `/planning/${body.id}/v2` + parseObjectToQueryString(query), body)
      .then(({ data }) => {
        successHandler('Операция изменена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteOperation(
    body: IPrPrEstimateOperation,
    query: IProductionProgramEstimateGetListQuery,
  ): Promise<void> {
    return await req
      .delete(this.baseURL + `/estimate/${body.id}`)
      .then(({ data }) => {
        return successHandler('Операция удалена')
      })
      .catch((error) => errorHandler(error))
  }
  static async getPlanningAndAnalitics(
    query: Partial<IBudgetYearlyReportQueryParams>,
  ): Promise<{ operations: IPrPrPlanningAndAnaliticsOperation[]; total: null; totalRecords: number }> {
    return await req
      .get(this.baseURL + '/planning/get-list' + parseObjectToQueryString(query))
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updatePlanningOperation(
    body: IPrPrPlanningAndAnaliticsOperation,
    query: Partial<IBudgetYearlyReportQueryParams> & { year: number | string },
  ): Promise<IPrPrPlanningAndAnaliticsOperation> {
    return await req
      .post(this.baseURL + `/planning/${body.id}` + parseObjectToQueryString(query), body)
      .then(({ data }) => {
        successHandler('Операция изменена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async downloadTemplateProductionProgram(name: string): Promise<void> {
    return await req
      .get('/production-program/template')
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', `Производственная программа ${name.replace(/[\"]/gm, '')}.xlsx`)
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }
  static async uploadTemplateProductionProgram(
    financeCenterID: string,
    projectID: string,
    body: FormData,
  ): Promise<ExcelUploadResponse> {
    return req
      .post(`/production-program/upload?projectID=${projectID}&financeCenterID=${financeCenterID}`, body)
      .then(({ data }) => {
        errorUploadHandler(data.error, 'upload')
        return data
      })
      .catch((error) => errorHandler(error))
  }
  static async getRMProjectList(limit: number, offset: number): Promise<IProjectRMList> {
    return await req
      .get(`/cross-module/rm/get-project-list?limit=${limit}&offset=${offset}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
  // production-program/rm-import?projectID=1&financeCenterID=2
  static async rmImport(financeCenterID: string, projectID: string): Promise<IRmImportResponse> {
    return await req
      .post(`/production-program/rm-import?projectID=${projectID}&financeCenterID=${financeCenterID}`)
      .then(({ data }) => {
        successHandler('Импортировано успешно, из РМ-проекта')
        return data
      })
      .catch((error) => errorHandler(error))
  }
  // production-program/cancel-upload?projectID=1&financeCenterID=2
  static async cancelUpload(projectID: string, financeCenterID: string): Promise<void> {
    return await req
      .post(`/production-program/cancel-upload?projectID=${projectID}&financeCenterID=${financeCenterID}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  // production-program/get-upload-progress?projectID=1&financeCenterID=2
  // https://redesign.api.finance.pragma.info/production-program/get-upload-progress?projectID=185&financeCenterID=52
  static async getUploadProgress(financeCenterID: string, projectID: string): Promise<IUploadProgress> {
    return await req
      .get(`/production-program/get-upload-progress?projectID=${projectID}&financeCenterID=${financeCenterID}`)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
}
